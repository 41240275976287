<template>
  <div class="product-detail">
    <h1>{{ product.name }}</h1>
    <vue-swiper 
      :loop="true"
      :autoplay="{ delay: 2000, disableOnInteraction: false }"
      v-if="product.images && product.images.length > 0"
    >
      <vue-swiper-slide v-for="(image, index) in product.images" :key="index" class="swiper-pro">
        <img :src="image" alt="Product Image">
      </vue-swiper-slide>
    </vue-swiper>
    <!-- <p>{{ product.description }}</p> -->
    <p>{{ product.productTypeName }}</p>
    <p>{{ product.companyName?product.companyName:'****' }}</p>
    <img :src="product.companyImg" alt="Product Image">
    <!-- 其他产品详细信息 -->
  </div>
</template>

<script>

export default {
  name: 'ProductDetail',
  data() {
    return {
      product: {}
    };
  },
  async created() {
    const productId = this.$route.params.id;
    await this.fetchProductDetail(productId);
  },
  methods: {
    async fetchProductDetail(id) {
      try {
        const response = await this.$ajax.get(`/api/media/product/getbyid`, {
          params: { id }
        });
        if (response.data && response.data.result) {

          const product = response.data.result;
          console.log(response.data)

          const productTypeResponse = await this.$ajax.get(`/api/media/producttype/getbyid`, {
            params: { id: product.productTypeId }
          });
          // console.log(product.id)
          product.productTypeName = productTypeResponse.data?.result?.name || 'Unknown';
          const productCompany = await this.$ajax.get(`/api/media/company/getbyid`, {
            params: { id: product.companyId }
          });
          // console.log(productCompany)
          product.companyName = productCompany.data?.result?.name || 'Unknown';
          product.companyImg = productCompany.data?.result?.logo || 'Unknown';
          this.product = product;
          console.log(product)
        }
      } catch (error) {
        console.error('There was an error fetching the product details!', error);
      }
    }
  }
};
</script>

<style>
.product-detail {
  padding: 20px;
}
.swiper-pro img {
  width: 100%;
}
</style>
