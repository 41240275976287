<template>
  <footer>
    <p>&copy; 2024 imould.com. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
/* Add styles for the footer */
footer {
  padding: 1px;
  background-color: #333;
  color: white;
  text-align: center;
}
</style>
