<template>
  <h1>Get more customers, get more sales.</h1>
  <p>The leading B2B platform for mould trade.</p>
  <div class="search-container">
    <div class="search-bar">
      <input v-model="query" type="text" placeholder="automotive mold" @keyup.enter="search" />
      <button @click="search">Search</button>
    </div>
    <div class="frequently-searched">
      <span>Frequently searched:</span>
      <button v-for="term in frequentlySearchedTerms" :key="term" @click="setSearchTerm(term)">
        {{ term }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data() {
    return {
      query: '',
      frequentlySearchedTerms: ['Bumper mold', 'Grille mold', 'Chair mold'],
    };
  },
  methods: {
    search() {
      console.log('Searching for:', this.query);
      // 这里添加实际的搜索逻辑，例如 API 请求
    },
    setSearchTerm(term) {
      this.query = term;
      this.search();
    },
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
}

.search-bar {
  display: flex;
  width: 60%;
  max-width: 800px;
  margin: 20px 0 30px;
}

.search-bar input {
  flex: 1;
  padding: 1em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 1em 0 0 1em;
  outline: none;
}

.search-bar button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 0 1em 1em 0;
}

.search-bar button:hover {
  background-color: #0056b3;
}

.frequently-searched {
  display: flex;
  align-items: center;
  font-size: 1em;
}

.frequently-searched span {
  margin-right: 10px;
  color: #ffffffaa
}

.frequently-searched button {
  padding: .35em 1.2em;
  margin-right: .7em;
  font-size: 1em;
  border: 1px solid #ffffffaa;
  background: none;
  color: #ffffffaa;
  cursor: pointer;
  border-radius: 15px;
}

.frequently-searched button:hover {
  background-color: #007bff;
  color: white;
}
</style>
