import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/scss/style.scss';
import axios from 'axios';
import { Swiper as VueSwiper, SwiperSlide as VueSwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const app = createApp(App);

// 创建一个 axios 实例
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // 使用环境变量的 API 基础 URL
  timeout: 10000, // 请求超时时间
});

// 将 axios 实例挂载到 Vue 原型上
app.config.globalProperties.$ajax = axiosInstance;

app.component('VueSwiper', VueSwiper);
app.component('VueSwiperSlide', VueSwiperSlide);

app.use(router).mount('#app')
