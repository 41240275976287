<template>
  <div>
    <section class="hero">
      <SearchBar />
    </section>
    <section class="explore">
      <!-- <h2>Explore lots of offerings tailored to your business needs</h2> -->
      <ExploreList />
    </section>
    <!-- <section class="categories">
      <h2>Explore lots of offerings tailored to your business needs</h2>
      <CategoryList />
    </section> -->
    <section class="products">
      <h2>Featured Products</h2>
      <ProductList />
    </section>
    <!-- <section class="companies">
      <h2>Featured Companies</h2>
      <CompanyList />
    </section> -->
  </div>
</template>

<script>
import ExploreList from '../components/ExploreList.vue';
import SearchBar from '../components/SearchBar.vue';
import CategoryList from '../components/CategoryList.vue';
import ProductList from '../components/ProductList.vue';
import CompanyList from '../components/CompanyList.vue';


export default {
  name: 'HomeView',
  components: {
    SearchBar,
    ExploreList,
    // CategoryList,
    ProductList,
    // CompanyList,
  },
};
</script>

<style scoped>

.categories, .products, .companies {
  padding: 20px;
}
</style>
