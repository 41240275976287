<template>
  <header>
    <img src="../assets/img/logo.png" />
    <nav>
      <router-link to="/">Home</router-link>
      <!-- Add more navigation links as needed -->
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
};
</script>

<style scoped>
/* Add styles for the header */
a{color: #fff;text-decoration: none;}
</style>
