<template>
  <div class="box">
    <div class="shang">
      <div class="header b60">
        <h2>Explore lots of offerings tailored to your business needs</h2>
      </div>
    <!-- <div class="content"> -->
      <div class="stats b30">
        <div class="stat b50" v-for="stat in stats" :key="stat.title">
          <p>{{ stat.value }}</p>
          <p>{{ stat.title }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="categories">
      <div class="category" v-for="category in categories" :key="category.id">
        <i class="iconfont" v-html="category.icon"></i>
        <p>{{ category.name }}</p>
      </div>
    </div> -->
    <div class="swiper-container categories">  
      <!-- <Swiper
        :options="{
          slidesPerView: 3,
          spaceBetween: 30,
        }"
      > -->
        <!-- <SwiperSlide v-for="(slide, index) in slides" :key="index">
          {{ slide.title }}
          {{ slide.content }}
        </SwiperSlide> -->
        <!-- <SwiperSlide v-for="category in categories" :key="category.id">  
          <div class="category"> -->
            <!-- 如果需要图标，可以取消注释下面这行代码，并确保category.icon是安全的HTML字符串 -->  
            <!-- <i class="iconfont" v-html="category.icon"></i> -->  
            <!-- <p>{{ category.name }}</p>  
          </div>  
        </SwiperSlide>
      </Swiper> -->
      <vue-swiper class="swiper"
        :slides-per-view="6"
        :space-between="10"
        :loop="true"
        :autoplay="{ delay: 200, disableOnInteraction: false }"
      > 
        <vue-swiper-slide v-for="category in categories" :key="category.id">  
          <div class="category">  
            <!-- 如果需要图标，可以取消注释下面这行代码，并确保category.icon是安全的HTML字符串 -->  
            <!-- <i class="iconfont" v-html="category.icon"></i> -->  
            <p>{{ category.name }}</p>  
          </div>  
        </vue-swiper-slide>  
  
      </vue-swiper>  
    </div>

      
    <!-- </div> -->
  </div>
</template>


<script>
  // import {Swiper,SwiperSlide} from 'swiper/vue';
  // import 'swiper/css';
  // import 'swiper/css/autoplay';
  // import 'swiper/css/pagination';
  // import 'swiper/css/navigation';

  export default {
    // components: {  
    //   Swiper,  
    //   SwiperSlide  
    // },
    // setup() {
    //   const onSwiper = (swiper) => {
    //     console.log(swiper);
    //   };
    //   const onSlideChange = () => {
    //     console.log('slide change');
    //   };
    //   return {
    //     onSwiper,
    //     onSlideChange,
    //   };
    // },
    data() {
      return {
        categories: [
          // {name: 'Automotive mould',icon:'&#xe89d;'},
          // {name: 'Daily necessities mould',icon:'&#xe8c1;'},
          // {name: 'Packaging mould',icon:'&#xe618;'},
          // {name: 'Household appliances mould',icon:'&#xec78;'},
          // {name: 'Compression mould',icon:'&#xe640;'},
          // {name: 'Machinery enterprise',icon:'&#xe61e;'},
        ],
        stats: [
          { title: 'Product categories', value: '50+' },
          { title: 'Mould manufacturers', value: '500+' },
          { title: 'Supplies', value: '260+' },
          { title: 'Countries and regions', value: '30+' }
        ],
        // swipercategories: {  
        //   // Swiper配置  
        //   slidesPerView: '2', // 自动根据slide的宽度来展示  
        //   spaceBetween: 1,  
        //   loop:true,
        //   autoplay: true,
        //   // roundLengths: true,
        //   speed:2000,
        //   autoplayDisableOnInteraction : false,
        //   // breakpoints:{
        //   //   480:{
        //   //     slidesPerView :3,
        //   //   },
        //   //   768:{
        //   //     slidesPerView:6,
        //   //   }
        //   // },
        //   // 如果需要分页器  
        //   pagination: {  
        //     el: '.swiper-pagination',  
        //   },  
        //   // 如果需要前进后退按钮  
        //   navigation: {  
        //     nextEl: '.swiper-button-next',  
        //     prevEl: '.swiper-button-prev',  
        //   },  
        // },  
      }
    },
    created() {
      this.fetchCategories();
    },
    methods: {
      async fetchCategories() {
        try {
          console.log(process.env.VUE_APP_API_BASE_URL)
          let response = await this.$ajax.get('/api/media/mouldtype/getalllist');
          if (response.data && response.data.result && response.data.result.data) {
            this.categories = response.data.result.data;
          }
        } catch (error) {
          console.error('There was an error fetching the companies!', error);
        }
      },
    },
  }
</script>

<style scoped>

/* .box {
  width: 100%;
  border: 1px solid #ccc;
  padding: 20px;
} */

.header {
  /* text-align: center; */
  margin-bottom: 20px;
}

h2 {
  font-size: 3em;
  font-weight: 500;width:90%
}

.content {
  display: flex;
  justify-content: space-between;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-top:2em
}

.category {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  i{
    font-size: 4em;
    margin-bottom: .3em;
  }
}

.category p {
  margin: 0;
  /* width: 8em; */
}

.stats {
  display: flex;flex-wrap: wrap;
}

.stat {
  /* margin-right: 20px;
  padding: 10px;
  border: 1px solid #ccc; */
  /* text-align: center; */
  position:relative;
  margin:1.3em 0;
  :before{
    content: '';
    position: absolute;
    border-left: 3px solid #979797;
    top:15%;left:0;width:100%;height: 70%;
  }
  p:first-child{
    font-size: 3em;color: #1082E9;font-weight: 700;padding-bottom: 0;
  }
}

.stat p {
  margin: 0;padding:10px 15px;
  line-height: 1;
}
.swiper-slide{
  width:10em
}
</style>