<template>
  <div class="product-list">
    <div class="product" v-for="product in products" :key="product.id">
      <router-link :to="{ name: 'ProductDetail', params: { id: product.id } }" class="product-link">
        <!-- <img :src="product.images" alt="Product Image" /> -->
        <vue-swiper 
          :loop="true"
          :autoplay="{ delay: 200, disableOnInteraction: false }"
          v-if="product.images && product.images.length > 0"
        >
          <vue-swiper-slide v-for="(image, index) in product.images" :key="index" class="swiper-pro">
            <img :src="image" alt="Product Image">
          </vue-swiper-slide>
        </vue-swiper>
        <h3>{{ product.name }}</h3>
        <!-- <p>{{ product.productTypeName }}</p> -->
        <!-- <p>{{ product.companyName?product.companyName:'****' }}</p> -->
        <!-- <img :src="product.companyImg" alt="Product Image"> -->
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  data() {
    return {
      products: [
        // { id: 1, name: 'Product 1', description: 'Description 1', image: 'path/to/image1.jpg' },
        // { id: 2, name: 'Product 2', description: 'Description 2', image: 'path/to/image2.jpg' },
        // Add more products as needed
      ],
    };
  },
  created() {
    this.productlist();
  },
  methods: {
    async productlist() {
      try {
        // const baseURL = process.env.VUE_APP_API_BASE_URL;
        const response = await this.$ajax.get(`/api/media/product/getpageresult`, {
          params: {
            Page: 3,
            Limit: 24,
            Where: "recommend = true and name.contains('m')"
          }
        });
        // console.log(response)

        if (response.data?.result?.data) {
          const products = response.data.result.data;

          // for (let product of products) {
          //   const productTypeResponse = await this.$ajax.get(`/api/media/producttype/getbyid`, {
          //     params: { id: product.productTypeId }
          //   });
          //   // console.log(product.id)
          //   product.productTypeName = productTypeResponse.data?.result?.name || 'Unknown';
          //   const productCompany = await this.$ajax.get(`/api/media/company/getbyid`, {
          //     params: { id: product.companyId }
          //   });
          //   // console.log(productCompany)
          //   product.companyName = productCompany.data?.result?.name || 'Unknown';
          //   product.companyImg = productCompany.data?.result?.logo || 'Unknown';
          // }

          this.products = products;
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    // async productlist() {
    //   try {
    //     console.log(process.env.VUE_APP_API_BASE_URL)
    //     let response = await this.$ajax.get('/api/media/product/getpageresult?Page=10&Limit=10&Where=recommend = true');
    //     if (response.data && response.data.result && response.data.result.data) {
    //       this.products = response.data.result.data;
    //     }
    //   } catch (error) {
    //     console.error('There was an error fetching the companies!', error);
    //   }
    // },
  },
};
</script>

<style scoped>
/* Add styles for the product list */
.swiper-pro{height: 150px;display: flex;align-items: center;justify-content: center;}
</style>
